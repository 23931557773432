@import url("https://fonts.googleapis.com/css?family=Bowlby+One+SC:400,500,600,700");

.mt-28 {
    margin-top: 28px;
}

#roll-panel {
}

#roll-panel .man-display {
    width: 417px;
    height: auto;
    margin: 20px auto 0;
    background-size: 100% 100%;
    position: relative;
}

#roll-panel .man-display .num-border {
    width: 400px;
    filter: contrast(1.0111);
    /*margin: 7px 0 0 12px;*/
    /*height: 200px;*/
}

#roll-panel .man-display .shoub {
    position: absolute;
    /*background: url('../images/control.png') no-repeat;*/
    width: 60px;
    height: 82px;
    right: -26px;
    top: 38px;
    cursor: pointer;
}

#roll-panel .man-display .shoub-left {
    position: absolute;
    width: 60px;
    height: 82px;
    left: 0px;
    top: 38px;
    cursor: pointer;
}

#roll-panel .man-display .lottery-numb {
    height: 88px;
    position: absolute;
    top: 42px;
    left: 39px;
    width: 100%;
    overflow: hidden;
}

#roll-panel .man-display .lottery-numb .roll-numb {
    /*background: url('../images/numbers.png') top repeat-y;*/
    font-family: Bowlby One SC;
    font-size: 60px;
    text-align: center;
    color: #FFEB3B;
    font-weight: 700;
    text-shadow: 0 3px 10px #111;
    width: 67px;
    /*height: 926.2px;*/
    float: left;
    -webkit-transition: all 450ms ease-in;
    transition: all 450ms ease-in;
}

#roll-panel .man-display .base-numb {
    position: absolute;
    top: 50%;
    left: -171px;
    margin-top: -32px;
    padding: 6px 15px;
    border-radius: 10px;
    font-size: 23px;
    color: #6e8899;
    border: 1px solid #3c4d5b;
}

#roll-panel .man-display .rt-more {
    left: auto;
    right: -183px;
}

@media (max-width: 621px){
    #roll-panel .man-display {
        position: relative;
        left: 50%;
        top: 0;
        margin-left: -208px;
        transform: scale(.70);
    }
}


@media (max-width: 621px){
    #roll-panel .man-display .base-numb {
        left: 50%;
        bottom: -2.1875rem;
        top: auto;
        width: 6.25rem;
        margin-left: -4.375rem;
        height: 1.625rem;
        border-radius: 1.25rem;
        text-align: center;
        padding: .375rem .9375rem;
        border: none;
        background-color: #2a3946;
        font-size: 1rem;
        line-height: 1.625rem;
    }
}
