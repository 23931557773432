div[id*="pcard"] .popover { left: -95px !important; }
div[id*="dcard"] .popover { left: -105px !important; }

.blackjack #wrapper {
	margin: 0 auto;
	width: 100%;
}

.blackjack #game {
	background: url(../images/table3.png) no-repeat, linear-gradient(180deg, #2b855c 0%, #141825 100%);
	/*background: url(/assets/images/table3.png) no-repeat;*/
    background-position: center bottom !important;
    color: #333;
    font: 14px/17px Helvetica, Arial, Verdana, sans-serif;
    height: 450px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    background-position: center top;
	background-size: contain;
}

.blackjack div#dscore {
	position: absolute;
	bottom: 30px;
}

.blackjack div#pscore {
	position: absolute;
	bottom: 15px;
}

.blackjack div#phand, div#dhand {
	font-weight: 700;
	/*left: 50%;*/
	position: absolute;
	top: -40;
	white-space: nowrap;
	width: 100%;
}

.blackjack div#phand {
    transform: rotate(-12deg);
}

.blackjack div#dhand {
    transform: rotate(12deg);
}

.blackjack div[class*="card"] {
	background: #FFF;
	border: 3px solid #FFF;
	border-radius: 5px;
	box-shadow: 0 0 1px #000;
	display: inline-block;
	height: 70px;
	margin: 0 5px;
	position: absolute;
	right: 40%;
	width: 45px;
}

.blackjack .down {
	background: #a95400 !important;
	background-size: 100% !important;
}

.blackjack span.pos-0 {
	left: 0;
	position: absolute;
	top: 0;
}

.blackjack span.pos-1 {
	bottom: 0;
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	position: absolute;
	right: 0;
	transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.blackjack span.rank {
	font-size: 18px;
}

.blackjack span.suit {
	font-size: 22px;
}

.blackjack .black {
	color: #000;
}

.blackjack .red {
	color: #F00;
}

.blackjack #result {
	position: absolute;
	bottom: 0;
}

.blackjack #actions {
	margin: 15px 0 0 0;
    text-align: center;
}

.blackjack input#wager { 
	margin-left: 5px;
	position: relative;
	top: 4px;
}

.blackjack #money, #money:before {
	border-radius: 5px;
	bottom: 0;
	padding: 7px;
	position: absolute;
	right: 0;
	width: 250px;
}

.blackjack #money {
	border: 2px solid #FFC926;
}

.blackjack #money:before {
	background: #333;
	content: '';
	opacity: 0.8;
}

.blackjack #cash, #bank {
	color: #FFF;
	font: 700 18px/20px Helvetica, Arial, Verdana, sans-serif;
	margin: 12px 0;
	position: relative;
	text-shadow: 1px 1px #000;
	z-index: 1;
}

.blackjack #alert {
	margin: 0 auto;
    opacity: 0.95;
    position: absolute;
    text-align: center;
    bottom: 5px;
    left: 5px;
    z-index: 1111;
    text-transform: uppercase;
    background: #995fff;
    color: #FFF;
    box-shadow: none;
}

.bji {
	position: absolute;
    top: 10px;
    left: 20px;
    color: #FFF;
}

.blackjack .alert.alert-success {
    background: #f44336;
}

.blackjack {
	position: relative;
}

@media screen and (max-width:621px) {
	.blackjack #game {
		background-size: cover;
		height: 350px;
    	background-position: center top;
	}

	.blackjack div#phand, div#dhand {
		left: 50% !important;
	}

	.blackjack #alert {
		left: unset;
		right: 5px;
	}

	.bji {
	    top: 5px;
	    left: 5px;
	    color: #FFF;
	    padding: 2px 5px;
	    border-radius: 3px;
	}

	.blackjack div[class*="card"] {
		width: 35px;
		height: 55px;
	}

	.blackjack span {
		font-size: 14px !important;
	}
}

.bj .btn-bet:before {
	display: none !important;
}